.ticket-container {
  position: relative;
  width: 100%;
  display: flex;
  min-height: 124px;

  &.condition-ticket {
    .ticket-wrapper {
      &.left-ticket-wrapper {
        // box-shadow: -2px 6px 23px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 0.650rem 0.25rem rgb(34 41 47 / 8%) !important;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          width: 25px;
          background-color: #fff;
          z-index: 1;
        }
      }
      &.right-ticket-wrapper {
        // box-shadow: 6px -2px 23px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 0px 0px 0.650rem 0.25rem rgb(34 41 47 / 8%) !important;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 25px;
          background-color: #fff;
          z-index: 1;
        }
      }
    }

    .ticket-mask {
      .ticket-dot-cut {
        &.upper-cut {
          // box-shadow: inset 0px -3px 5px 0px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0px 0px 0.250rem 0.25rem rgb(34 41 47 / 4%) !important;
        }
        &.bottom-cut {
          // box-shadow: inset 0px 3px 5px 0px rgba(0, 0, 0, 0.05);
          box-shadow: inset 0px 0px 0.250rem 0.25rem rgb(34 41 47 / 4%) !important;
        }
      }
    }
  }


  &.select-ticket {
    .ticket-wrapper {
      &.left-ticket-wrapper,
      &.right-ticket-wrapper{
        border-style: solid;
        border-color: #e5e5e5;
      }

      &.left-ticket-wrapper {
        border-radius: 1rem 0px 0px 1rem;
        border-width: 1px 0px 1px 1px;
      }

      &.right-ticket-wrapper {
        border-radius: 0px 1rem 1rem 0px;
        border-width: 1px 1px 1px 0px;
      }
    }

    .ticket-mask {
      z-index: 0;
      .ticket-dot-cut {
        border-width: 1px;
        border-style: solid;
        border-color: #e5e5e5;
      }
    }
  }
}

.ticket-wrapper {
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: #fff;
  width: 100%;
  position: relative;
  &.left-ticket-wrapper {
    justify-content: center;
    width: calc(67% - 25px);
    padding-right: 25px;
    border-radius: 1rem 4px 4px 1rem;
  }

  &.right-ticket-wrapper {
    width: calc(33% - 25px);
    border-radius: 4px 1rem 1rem 4px;
    padding-left: 25px;
  }
}

.ticket-mask {
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 3;
  .ticket-dot-cut {
    position: absolute;
    background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 50%;

    &.upper-cut {
      top: -25px;
    }
    &.bottom-cut {
      bottom: -25px;
    }
  }
}
