.default-box{
  width: 100%;
  max-width: 462px;
  padding: 32px;
  gap: 30px;
  align-self: 'flex-start';


  @include media-breakpoint-down(lg){
    padding: 21px;
    gap: 20px;
  }
}
