// Benefits
.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;

  .row {
    justify-content: center;
    column-gap: 1rem;

    align-items: center;
    .col {
      padding-right: 0;
      .creator-img {
        display: none;
        width: 57px;
        height: 57px;
        border-radius: 100%;
      }
    }
  }

  .access-condition-container {
    width: 12rem;

    .access-condition-field {
      cursor: pointer;

      span {
        color: #00cfe8;
        font-weight: 600;
      }

      .total-access-condition-label {
        background-color: #00cfe8;
        color: white;
        border-radius: 100%;
        width: 17px;
        height: 17px;
        font-weight: bold;
        font-size: 13px;

        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .benefit-and-collectible-fields {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;

    .benefit-field {
      display: flex;
      justify-content: flex-start;
      width: 12rem;
      size: scale-down;
    }

    .collectible-field {
      display: flex;
      align-items: center;
      gap: 1rem;
      width: 12rem;
    }
  }

  .benefit-condition-field {
    width: 100%;
    gap: 2rem;

    .benefit-total-conditions-label {
      border-radius: 100%;
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 14px;
      background-color: #009fe3;
    }
  }

  .benefit-status-and-date-fields {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;

    .status-field {
      width: 12rem;
      justify-self: flex-start;
    }

    .benefit-date-field {
      width: 12rem;
    }
  }

  .benefit-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
    width: 100%;

    .benefit-details-button {
      padding-right: 2rem;
      padding-left: 2rem;
      width: 12rem;
    }

    .qrcode-field {
      justify-content: flex-start;
      width: 12rem;
    }
  }

  @media (min-width: 575px) {
    .benefit-and-collectible-fields {
      gap: 5rem;
    }
    .benefit-status-and-date-fields {
      gap: 5rem;
    }
    .benefit-buttons {
      gap: 5rem;
    }
  }

  @media (min-width: 650px) {
    flex-direction: row;
    align-items: center;

    .benefit-and-collectible-fields {
      justify-content: flex-start;
      gap: 1rem;

      .benefit-field,
      .collectible-field {
        display: flex;
        width: 100%;
      }
    }
    .benefit-status-and-date-fields {
      flex-direction: column;
      gap: 1rem;
    }
    .benefit-buttons {
      height: 100%;
      gap: 2rem;

      .qrcode-field {
        margin: 0 !important;
      }
    }
  }

  @media (min-width: 768px) {
    .creator-img {
      display: block !important;
    }
  }

  @media (min-width: 850px) {
    .benefit-and-collectible-fields {
      gap: 2rem;
    }
    .benefit-status-and-date-fields {
      gap: 2rem;
    }
    .benefit-buttons {
      gap: 3rem;
    }
  }

  @media (min-width: 950px) {
    .benefit-and-collectible-fields {
      width: 35rem;
    }

    .benefit-status-and-date-fields {
      width: 20rem;
    }

    .benefit-buttons {
      flex-direction: row;
      flex-wrap: nowrap;
      width: initial;

      .qrcode-field {
        zoom: 1.3;
      }
    }
  }

  @media (min-width: 1300px) {
    gap: 5rem;

    .benefit-and-collectible-fields {
      .benefit-field {
        display: flex;
        width: 14rem;
      }

      .collectible-field {
        display: flex;
        align-items: center;
        gap: 1rem;
        width: 15rem;
      }
    }
  }

  @media (min-width: 1350px) {
    gap: 1rem;

    .row {
      justify-content: flex-start !important;
    }

    .benefit-and-collectible-fields {
      flex-direction: row;
      flex-wrap: nowrap;
    }

    .benefit-status-and-date-fields {
      .status-field {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 10rem;
      }

      .benefit-date-field {
        display: none;
      }
    }

    .benefit-buttons {
      .benefit-details-button {
        width: 12rem;
      }

      .qrcode-field {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 10rem;
        zoom: normal;
      }
    }
  }

  @media (min-width: 1450px) {
    gap: 0.8rem;

    .benefit-and-collectible-fields {
      gap: 0.5rem;
    }

    .benefit-status-and-date-fields {
      flex-direction: row;
      flex-wrap: nowrap;

      .benefit-date-field {
        display: inline;
        width: 10rem;
      }
    }

    .benefit-buttons {
      gap: 1rem;
    }
  }

  @media (min-width: 1490px) {
    gap: 1rem;

    .row {
      .col {
        width: max-content;
      }

      .col.benefit-date-field {
        width: 12rem;
      }
    }

    .benefit-and-collectible-fields {
      justify-content: space-around;
      align-items: center;

      gap: 1rem;
    }

    .access-condition-container {
      width: 15rem;

      .access-condition-field {
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }

    .benefit-status-and-date-fields {
      justify-content: space-around;
      width: 100%;
      gap: 1rem;
    }

    .benefit-buttons {
      justify-content: space-around;
      width: 100%;
      gap: 1.5rem;
    }
  }
}
