//Heading Typography

.heading-1{
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;

  color: #454545;
}

.heading-2{
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;

  color: #454545;
}

.heading-6{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: #454545;
}

.modal-heading{
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  
  color: #454545;
}