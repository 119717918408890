$margin-multiplier: 0.25;

.row-50 {
  [dir=ltr] .row {
    margin-right: -0.50rem;
    margin-left: -0.50rem;
  }
}

.row-75 {
  [dir=ltr] .row {
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }
}