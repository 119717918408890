$condition-card: "condition-card";
$condition-card-body: "#{$condition-card}-body";

.#{$condition-card} {
  @extend .m-0;
  @extend .rounded-1;

  .#{$condition-card-body} {
    @extend .p-75;

    .#{$condition-card-body}-row {
      @extend .d-flex;
      @extend .px-75;
      
      margin-right: -0.75rem;
      margin-left: -0.75rem;

      > *{
        padding-right: 0.375rem;
        padding-left: 0.375rem;
      }

      & > *:first-child {
        padding-left: 0rem;
      }

      & > *:last-child {
        padding-right: 0rem;
      }
    }
  }
}
