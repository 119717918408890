.default-navigation-swiper {
  .swiper-pagination {
    width: 100%;
    text-align: center;
    position: static;
    height: 24px;
    margin: 1rem 0rem;

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
      margin: 0 2px;
      opacity: 0.5;
      background-color: transparent;
      border: 1px solid #333;
      cursor: pointer;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: 50%;
    border: 1px solid #333;
    opacity: 0.5;
    border-radius: 50%;
    background-color: white;

    width: 32px;
    height: 32px;
    z-index: 11;

    &:after {
      font-size: 12px;
      color: #333;
      background-size: 18px 18px;
    }

    &.swiper-button-disabled {
      display: none;
    }
  }
}
