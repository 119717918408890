/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import "./weispace/benefits.scss";

#no-hover:hover {
  background-color: #ffffff;
}

.go-link-underline-hover:hover {
  text-decoration: underline;
}

.go-swipper {
  padding: 0rem;
  .go-swipper-slides-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    gap: 1rem;
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  .go-swipper-slide {
    cursor: pointer;
  }
}

#cardResponsive {
  width: 100%;
}

#imgResponsive {
  max-width: 300px;
}

@media (min-width: 576px) {
  #cardResponsive {
    width: 100%;
    max-width: 350px;
  }

  #imgResponsive {
    max-width: 300px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  #cardResponsive {
    max-width: 350px;
  }

  #imgResponsive {
    max-width: 350px;
  }
}

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  #cardResponsive {
    max-width: 377px;
  }

  #imgResponsive {
    max-width: 430px;
  }
}
