@mixin scroll-content {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 100%;

  &::-webkit-scrollbar {
    width: 10px;
    color: rgba(69, 69, 69, 1);
    border-radius: 5px;
    margin-right: 5px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
    border: 3px solid transparent;
    background-clip: padding-box; // <== make the border work
    min-width: 7px;
    &:hover {
      border: 2px solid transparent;
    }
  }

  &::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    // border-radius: 10px;
    // background-color: #f5f5f5;
    margin: 1.5rem;
  }
}

.modal-fullscreen-view {
  margin: 0;
  height: 100vh;
}

.modal-fullscreen-view .modal-dialog {
  margin: 0;
  padding: 1rem;
  height: 100%;
}

.modal-fullscreen-view .modal-content {
  max-height: 100%;
  height: auto;
}

.modal-fullscreen-view .modal-body {
  @include scroll-content;
}

.validate-benefit-gating-card {
  @include scroll-content;
}

.scrollable {
  @include scroll-content;
}
