//Banner

.banner {
  display: flex;
  padding-top: 84px;
}

.banner-shadow {
  background: linear-gradient(204deg,
      rgba(14, 1, 35, 0.7) 19.32%,
      rgba(24, 0, 55, 0.259) 34.32%,
      rgba(111, 0, 255, 0) 83.86%);
}

.banner-icons-container {
  gap: 10px;
  padding: 21px;
  right: 0;
  top: 0;
}

@media screen and (max-width: 768px) {
  .banner {
    padding-top: 81px;
  }

  .banner-icons-container {
    gap: 8px;
    padding: 18px;
  }
}

@media screen and (max-width: 575px) {
  .banner {
    padding-top: 67px;
  }

  .banner-icons-container {
    gap: 6px;
    padding: 15px;
  }
}

@media screen and (max-width: 284px) {
  .banner {
    padding-top: 115px;
  }
}