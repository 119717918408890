$animation_effect_transform: 50px;
$animation_effect_delay: 0.3s;

@mixin step-content-moving($direction) {
  @if $direction == "prev" {
    animation: reverse-moving $animation_effect_delay ease forwards;
  } @else {
    animation: moving $animation_effect_delay ease forwards;
  }
}

.prev-effect-y {
  @include step-content-moving("prev");
  @keyframes reverse-moving {
    from {
      transform: translateY(-$animation_effect_transform);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.next-effect-y {
  @include step-content-moving("next");

  @keyframes moving {
    from {
      transform: translateY($animation_effect_transform);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.prev-effect-x {
  @include step-content-moving("prev");

  @keyframes reverse-moving {
    from {
      transform: translateX(-$animation_effect_transform);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.next-effect-x {
  @include step-content-moving("next");

  @keyframes moving {
    from {
      transform: translateX($animation_effect_transform);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

.next-effect-x-child {
  > * {
    @extend .next-effect-x;
  }
}
