$display-source: "display-source";
$display-source-container: "#{$display-source}-container";
$display-source-wrapper: "#{display-source}-wrapper";
$display-source-image: "#{$display-source}-image";
$display-source-video: "#{$display-source}-video";

@mixin display-source-content {
  @extend .position-absolute;
  @extend .top-0;
  @extend .start-0;
  @extend .w-100;
  @extend .h-100;
}

.#{$display-source-container} {
  @extend .w-100;

  .#{$display-source-wrapper} {
    @extend .position-relative;
    @extend .w-100;
    @extend .overflow-hidden;

    padding-bottom: 100%;

    .#{$display-source-image} {
      @include display-source-content;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover;
      object-position: center center;
    }

    .#{$display-source-video} {
      @include display-source-content;

      @extend .d-flex;

      object-fit: cover;
      object-position: center center;
    }

    &.display-contained {
      .#{$display-source-image} {
        background-size: contain;
        object-fit: contain;
      }
      .#{$display-source-video} {
        object-fit: contain;
      }
    }
  }
}

.challenge-display {
  @include media-breakpoint-down(sm) {
    max-width: 4rem;
  }
}

.challenge-reward-display {
  @include media-breakpoint-down(sm) {
    max-width: 1.5rem;
  }
}
