//Paragraph Typography

.paragraph-small{
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  
  color: #454545;
}

.paragraph-small-300 {
  @extend .paragraph-small;
  font-weight: 300;
}

.uppercase{
  text-transform: uppercase;
}

.modal-paragraph{
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;

  color: #454545;
}

.paragraph-medium-light {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;

  @include media-breakpoint-down(sm){
    font-size: 14px;
    line-height: 16px;
  }
}