//icons

.social-media-icons{
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 768px){
  .social-media-icons{
    width: 20px;
    height: 20px;
  }
}

@media screen and (max-width: 575px) {
  .social-media-icons{
    width: 16px;
    height: 16px;
  }
}
