// Base Extended (General styles which are not added 'base styles')
@import 'base-extended';

@import 'dark-layout';

@import 'rtl';

@import 'reactstrap/index';

@import 'app-loader';


.HoverShadownCustomized:hover {
    transition: all 0.3s ease-in-out;
    // opacity: 0.5;
    background-color: #E6E6E6;
}