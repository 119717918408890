//Token
.token-container {
  max-width: 1468px;

  @include media-breakpoint-down(lg) {
    flex-direction: column;
    align-items: center;
  }
}

.token-main-info {

  top: 120px;
  padding-top: 30px;

  @include media-breakpoint-down(lg) {
    position: static;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.token-main-info-button {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0px;

  &.visible {
    height: auto;
  }
}

.token-collapse-description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
}

.token-title-name {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;

  color: #454545;
}

.token-create-at {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #a19d9d;
}

.token-collapse-divider {
  border: 1px solid #d9d9d9;
  width: 100%;
}

.token-collection-description-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 10px;
}

.token-benefit-interaction {
  min-width: 100px;
  min-height: 100px;

  @include media-breakpoint-down(sm) {
    min-width: 100%;
    min-height: 50px;
  }
}
