// ================================================================================================
//     File Name: Footer.scss
//     Description: *  Main footer styles.
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================
// Stick footer to the bottom of the page
footer {
  &.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 5rem;

    .footer-links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;

      span {
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 0.9rem;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    // padding: 0.8rem $content-padding;
    min-height: $footer-height;
    transition: 300ms ease all;
    // heart icon scss
    span {
      i,
      svg {
        height: 1.51rem;
        width: 1.51rem;
        font-size: 1.51rem;
        margin-left: 0.5rem;
        color: $danger;
      }
    }
    .scroll-top {
      padding: 0.81rem 0.83rem;
    }
  }
  //shadow & border options
  &.footer-shadow {
    box-shadow: $box-shadow;
  }
  &.footer-border {
    border-top: 1px solid $border-color;
  }
}
// Fixed Footer
.footer-fixed {
  footer {
    position: fixed !important;
    z-index: 1030;
    right: 0;
    left: 0;
    bottom: 0;
    // Footer Light
    &.footer-light {
      background: $white;
      box-shadow: $box-shadow;
    }
    // Footer Dark
    &.footer-dark {
      background: $gray-600;
      color: $white;
    }
  }
}
//! Fix: If footer is hidden add padding fix to avail card margin bottom
.footer-hidden {
  .app-content {
    padding-bottom: 0.1px !important;
  }
}
// scroll to Top Footer Button
.scroll-top {
  position: fixed;
  bottom: 5%;
  right: 30px;
  display: none;
  z-index: 99;
}

@media screen and (max-width: 767px) {
  footer {
    &.footer {
      display: flex;
      flex-direction: column;
      padding-top: 1.5rem;

      .footer-links {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 1.5rem 0;
        align-items: center;
        gap: 0;
      }
    }
  }
}

.qrcode-footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.25rem 2.5rem;
  color: #F8F8F8;
  box-shadow: 0px -3.5px 11px rgba(0, 0, 0, 0.09);

  .qrcode-footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    span{
      cursor: pointer;
  
      &:hover {
        opacity: 0.6;
      }
    }

    // padding: 0.8rem $content-padding;
    min-height: $footer-height;
    transition: 300ms ease all;
    .scroll-top {
      padding: 0.81rem 0.83rem;
    }
  }

  .qrcode-footer-gotokens-logo{
    width: 120px;
  }

  @include media-breakpoint-down(md){
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;

  }

  @include media-breakpoint-down(sm){
    .qrcode-footer-gotokens-logo{
      width: 100px;
    }

    .qrcode-footer-links{
      gap: 1 rem;

      span {
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 0.9rem;
      }
    }
  }
}