$productCard: "product-card";
$productCardHorizontalCol: "#{$productCard}-horizontal-col";
$productCardDivisor: "#{$productCard}-divisor";
$productCardTumb: "#{$productCard}-tumb";
$productCardContent: "#{$productCard}-content";
$productCardContentDescription: "#{$productCardContent}-description";
$productCardContentMainInfo: "#{$productCardContent}-main-info";
$productCardContentCoinsGroup: "#{$productCardContent}-coins-group";
$productCardActions: "#{$productCard}-actions";
$productCardActionsButtons: "#{$productCardActions}-buttons";
$productCardStandard: "#{$productCard}-standard";
$productCardDetailed: "#{$productCard}-detailed";
$productCardCompact: "#{$productCard}-compact";

//* Card Roots
@mixin product-card($add-hover: false, $isHorizontal: false) {
  @extend .d-flex;
  @extend .m-0;
  @extend .position-relative;
  @extend .rounded;
  @extend .cursor-pointer;

  @if $add-hover {
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 4px 25px 0 rgba($black, 0.25);
    }
  }

  @if $isHorizontal {
    .#{$productCardHorizontalCol}-left {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
  
      @extend .col-12;
      @extend .col-md-5;
      @extend .col-lg-4;
      @extend .col-xl-3;
    }
    
    .#{$productCardHorizontalCol}-right {
      @extend .d-flex;
      @extend .flex-column;
      @extend .gap-1;
      @extend .justify-content-between;
  
      @extend .col-12;
      @extend .col-md-7;
      @extend .col-lg-8;
      @extend .col-xl-9;
    }
  }
}

@mixin utility-coin {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.utility-coin-sm {
  @include utility-coin;

  width: 1.25rem;
  height: 1.25rem;
}

.utility-coin-md {
  @include utility-coin;

  width: 1.75rem;
  height: 1.75rem;
}

.utility-coin-lg {
  @include utility-coin;

  width: 2.25rem;
  height: 2.25rem;
}


//** Divisor
.#{$productCardDivisor}-horizontal {
  @extend .border-top;
}

.#{$productCardDivisor}-vertical {
  @extend .d-flex;
  @extend .border-start;
  @extend .h-100;
}

//** Tumb
.#{$productCardTumb}-container {
  @extend .position-relative;
  @extend .w-100;
  @extend .rounded;
  @extend .overflow-hidden;

  padding-bottom: 100%;

  .#{$productCardTumb}-content {
    @extend .position-absolute;
    @extend .top-0;
    @extend .start-0;
    @extend .w-100;
    @extend .h-100;
    @extend .rounded;

    object-fit: cover;
  }

  .#{$productCardTumb}-display {
    @extend .position-absolute;
    @extend .top-0;
    @extend .start-0;
    @extend .w-100;
    @extend .h-100;
    @extend .rounded;

    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

//** Content
.#{$productCardContentMainInfo}-wrapper {
  @extend .d-flex;
  @extend .flex-column;
  @extend .gap-25;

  .#{$productCardContentMainInfo}-name {
    @extend .fs-5;
    @extend .m-0;
    font-weight: bold;
  }

  .#{$productCardContentMainInfo}-owner-wrapper {
    @extend .d-flex;
    @extend .gap-25;
    @extend .align-items-center;
    @extend .w-100;

    .#{$productCardContentMainInfo}-owner {
      @extend .m-0;
      @extend .fs-6;
    }
  }

  .#{$productCardContentMainInfo}-official-icon {
    color: $info;
    min-width: 20px;
    min-height: 20px;
  }
}

.#{$productCardContentDescription}-wrapper {
  @extend .h-100;

  .#{$productCardContentDescription} {
    @extend .m-0;
  }
}

.#{$productCardContentCoinsGroup}-wrapper {
  @extend .d-flex;
  @extend .align-items-center;
}

.#{$productCardContentCoinsGroup}-values-wrapper {
  @extend .d-flex;
  @extend .align-items-center;
  @extend .gap-50;
  @extend .flex-wrap;
}

//** Actions
.#{$productCardActions}-container {
  @extend .d-flex;
  @extend .gap-75;
  @extend .flex-column;
  @extend .w-100;

  .#{$productCardActions}-divisor {
    @extend .border-top;
  }

  .#{$productCardActions}-wrapper {
    @extend .d-flex;
    @extend .gap-1;
    @extend .align-items-center;
    @extend .w-100;

    .#{$productCardActionsButtons}-buy,
    .#{$productCardActionsButtons}-wishlist,
    .#{$productCardActionsButtons}-share {
      @extend .p-75;
    }

    .#{$productCardActionsButtons}-buy,
    .#{$productCardActionsButtons}-wishlist {
      @extend .px-2;
    }

    .#{$productCardActionsButtons}-wishlist {
      @extend .d-flex;
      @extend .align-items-center;
      @extend .justify-content-center;
      @extend .gap-25;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;

      .#{$productCardActionsButtons}-buy,
      .#{$productCardActionsButtons}-wishlist {
        width: 100%;
      }

      .#{$productCardActionsButtons}-wishlist {
        gap: 0.5rem;
      }
    }
  }
}

//* Cards
.#{$productCardStandard}-row {
  @include media-breakpoint-down(md) {
    > *:nth-child(odd) {
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      padding-right: calc(var(--bs-gutter-x) * 0.25);
    }

    > *:nth-child(even) {
      padding-left: calc(var(--bs-gutter-x) * 0.25);
      padding-right: calc(var(--bs-gutter-x) * 0.5);
    }

    @media screen and (max-width: 390px) {
      padding-left: calc(var(--bs-gutter-x) * 0.25);
      padding-right: calc(var(--bs-gutter-x) * 0.25);
    }
  }
}
.#{$productCardStandard}-grid {
  @extend .col-12;

  margin-bottom: var(--bs-gutter-x);

  @include media-breakpoint-up(xxl) {
    width: 25%;
  }

  @include media-breakpoint-down(xxl) {
    width: 33.333333%;
  }

  @include media-breakpoint-down(lg) {
    width: 50%;
    margin-bottom: calc(var(--bs-gutter-x) * 0.5);
  }

  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.#{$productCardStandard} {
  @include product-card(true);

  @extend .h-100;
  @extend .p-75;
  @extend .flex-column;
  @extend .gap-75;
}

.#{$productCardDetailed} {
  @include product-card(false, true);

  @extend .p-2;
  @extend .gap-2;
  @extend .flex-row;

  .#{$productCardTumb}-container {
    max-height: 25rem;

    .#{$productCardTumb}-content {
      max-width: 25rem;
    }
  }

  .#{$productCardContentMainInfo}-wrapper {
    .#{$productCardContentMainInfo}-name {
      @extend .fs-4;
    }
    .#{$productCardContentMainInfo}-owner {
      @extend .fs-5;
    }
  }

  @include media-breakpoint-up(md) {
    > div:last-child {
      padding-right: 1.5rem;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column !important;
    > div:first-child {
      width: 100%;
    }
  }
}

.#{$productCardCompact} {
  @include product-card;

  max-width: 280px;
  @extend .p-2;
  @extend .flex-column;
  @extend .align-items-center;
  @extend .justify-content-between;
  @extend .gap-1;

  .#{$productCardContentMainInfo}-wrapper {
    @extend .w-100;
    @extend .align-items-center;

    .#{$productCardContentMainInfo}-name {
      @extend .d-flex;
      @extend .justify-content-center;
      @extend .text-truncate;
    }
  }

  .#{$productCardContentMainInfo}-owner-wrapper {
    @extend .justify-content-center;
  }
}
