//Token-Card
.token-card {
  transition: transform 0.3s ease-in-out;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  cursor: pointer;
  overflow: hidden;
  width: 320px;

  @include media-breakpoint-down(sm){
    width: 240px;
  }
}

.token-card-column{
  &.column-4{
    @media screen and (min-width: 1080px) and (max-width: 1440px){
      flex: 0 0 auto;
      width: 33.33333333%;
    }
  }
  &.column-auto{
    @media screen and (min-width: 1080px) and (max-width: 1440px){
      flex: 0 0 auto;
      width: auto;
    }
  }
  &.column-6{
    @media screen and (min-width: 724px) and (max-width: 768px){
      flex: 0 0 auto;
      width: 50%;
    }

  }
}

.token-card-header-container{
  display: flex;
  justify-content: center;
  align-items: center;

  &.token-video-container{
    max-width: 300px;
    height: 300px;

    .token-video {
      display: flex;
      object-fit: cover;
      object-position: center;
      height: 100%;
      width: 100%;
    }

    @include media-breakpoint-down(sm){
      width: 220px;
      height: 220px;
    }
  }

  &.token-img-container{
    width: 100%;
    height: 300px;

    .token-img{
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 100%;
      height: 100%;
    }

    @include media-breakpoint-down(sm){
      width: 220px;
      height: 220px;
    }
  }
}

.token-card-body-container{
  .token-card-col{
    &.token-col-big{
      @extend .col-xs-8 !optional;  
    }
    &.token-col-small{
      @extend .col-xs-4 !optional;
    }
    @include media-breakpoint-down(sm){
      &.token-col-big,
      &.token-col-small{
        @extend .col-xs-6 !optional;
      }
    }
  }
}

.token-card-footer-benefits{
  padding: 1rem;
  margin: 0;

  .token-col-benefits{
    @extend .col-xs-10 !optional;
  }

  .token-col-benefits-loading{
    @extend .col-xs-4 !optional;
  }

  @include media-breakpoint-down(sm){
    padding: 0.5rem;
    border-top: 0;

    .token-col-benefits{
      @extend .col-xs-8 !optional;
    }

    .token-col-benefits-loading{
      @extend .col-xs-2 !optional;
    }
  }
}

.token-card-footer-button{
  h4 {
    @include media-breakpoint-down(sm){
      font-size: medium;
    }
  }
}

.empty-token-col{
  @include media-breakpoint-down(lg){
    display: none;
  }
}
