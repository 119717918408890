.disabled-forms {
  input,
  button,
  select,
  textarea,
  checkbox,
  radio,
  optgroup,
  option {
    cursor: "not-allowed";
    pointer-events: none;
    opacity: 0.5;
  }
}

.input-error-shake {
  animation: shake 0.2s ease-in-out 0s 2;
  @keyframes shake {
    0% {
      margin-left: 0rem;
    }
    25% {
      margin-left: 0.25rem;
    }
    75% {
      margin-left: -0.25rem;
    }
    100% {
      margin-left: 0rem;
    }
  }
}
