//buttons
.button-icon {
  display: inline-block;
  background: #f1f1f1;
  padding: 8px;
  border-radius: 50%;
  box-shadow: 0 5px 15px -5px #00000070;
  overflow: hidden;
  position: relative;
  transition: 0.2s linear;

  svg {
    line-height: 90px;
    font-size: 26px;
    transition: 0.2s linear;
    color: rgb(0, 159, 227);
  }

  &:hover {
    svg {
      transform: scale(1.1);
      color: #6e6b7b;
    }
  }

  &.twitter {
    order: 1;
  }
  &.instagram {
    order: 2;
  }
  &.website {
    order: 3;
  }
  &.facebook {
    order: 4;
  }
  &.linkedin {
    order: 5;
  }
}

.action-button {
  transition: 0.2s;
  &:hover {
    opacity: 0.5;
  }
}

.action-button-75 {
  transition: 0.2s;
  &[aria-checked="false"] {
    cursor: pointer;
    &:hover {
      opacity: 0.75;
    }
  }

  &[aria-checked="true"] {
    cursor: default;
  }
}
